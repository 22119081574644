/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

/* =======================
    TABLE OF CSS
    1. Fonts 
    2. General 
    3. Left Menu 
    4. Topbar 
    5. Page Head 
    6. Footer 
    7. Horizontal Nav 
    8. Right Sidebar 
    9. Layouts 
    10. Helper 
    11. Social 
    12. Widgets 
    13. Custom Checkbox 
    14. Custom Radio 
    15. Print 
    16. Preloader 
    17. Plugins 
    18. Components Demo 
    19. Tasks 
    20. Email 
    21. Timeline 
    22. Extra Pages 
    23. Authentication
    24. Icons 
   ======================= */



//Fonts
/* 1. Fonts */
@import "custom/fonts/fonts";

//Core files

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// in order to have dark theme, just enable this and disable below line
//@import "variables-dark";
@import 'variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/bootstrap';
// in order to have dark theme, just enable this and disable below line
// @import 'bootstrap-dark';
@import 'bootstrap';
@import '~bootstrap-vue/src/index.scss';

// Structure
/* 2. General */
@import "custom/structure/general";
/* 3. Left Menu */
@import "custom/structure/left-menu";
/* 4. Topbar */
@import "custom/structure/topbar";
/* 5. Page Head */
@import "custom/structure/page-head";
/* 6. Footer */
@import "custom/structure/footer";
/* 7. Horizontal Nav */
@import "custom/structure/horizontal-nav";
/* 8. Right Sidebar */
@import "custom/structure/right-sidebar";
/* 9. Layouts */
@import "custom/structure/layouts";

//Components
/* 10. Helper */
@import "custom/components/helper";
/* 11. Social */
@import "custom/components/social";
/* 12. Widgets */
@import "custom/components/widgets";
/* 13. Custom Checkbox */
@import "custom/components/custom-checkbox";
/* 14. Custom Radio */
@import "custom/components/custom-radio";
/* 15. Print */
@import "custom/components/print";
/* 16. Preloader */
@import "custom/components/preloader";

// Plugins
/* 17. Plugins */
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/calendar';
@import 'custom/plugins/multiple-select';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/parsley';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/datatable';
@import 'custom/plugins/table-list-js';
@import 'custom/plugins/kanban';
@import 'custom/plugins/metismenu';
@import 'custom/plugins/editor';
@import 'custom/plugins/_fixesVuesax';

//Pages
/* 18. Components Demo */
@import "custom/pages/components-demo";
/* 19. Tasks */
@import "custom/pages/tasks";
/* 20. Email */
@import "custom/pages/email";
/* 21. Timeline */
@import "custom/pages/timeline";
/* 22. Extra Pages */
@import "custom/pages/extra-pages";
/* 23. Authentication */
@import "custom/pages/authentication";

/* 24. Icons */
@import 'icons';

.footer-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 98;
    background-color: transparent;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.06);
    min-height: 60px;
    // height: calc(62px + (constant(safe-area-inset-bottom))*1.1);
    // height: calc(62px + (env(safe-area-inset-bottom))*1.1);
    // display: flex;
    text-align: center;
}

.no-padding{
    padding: 0;
}

.pull-right {
    float: right;
}