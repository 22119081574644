/*=========================================================================================
  File Name: _fixesVuesax.scss
  Description: Partial - Fixes/Add vuesax framework styles
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


// ======================================================================================
// Alert
// ======================================================================================

// Alert shadow and color issue
.con-vs-alert {
    box-shadow: none !important;
  }
  
  .vs-alert {
    font-size: 1rem;
    font-weight: 500;
  
    code {
      background: #b5b5b5;
      color: $white;
    }
  }
  
  // .vs-alert {
  //   code.language-markup {
  //     background: transparent !important;
  //     color: #636363 !important;
  //   }
  // }
  
  .vs-alert--title {
    color: inherit;
  }
  
  
  
  // ======================================================================================
  // Avatar
  // ======================================================================================
  
  .vs-avatar--text.feather {
    font-size: 1.3rem;
  }
  
  .vs-avatar--con-img {
    img {
      height: 100%;
    }
  }
  
  
  // ======================================================================================
  // Button
  // ======================================================================================
  
  .vs-button {
  
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 1rem;
  
    &.vs-button-gradient {
      &:hover {
        box-shadow: none !important;
      }
    }
  
    &:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
      padding: .75rem 2rem;
  
      &.vs-button-border {
        padding: .679rem 2rem;
      }
  
    }
  
    &.small:not(.includeIconOnly) {
      padding: 0.5rem 1.5rem;
    }
  
    &.large:not(.includeIconOnly) {
      padding: 1rem 2.5rem;
    }
  
    &.large {
      font-size: 1.25rem;
  
      .vs-button--icon {
        font-size: 1.25rem;
      }
    }
  
    &.round {
      border-radius: 1.5rem;
    }
  
    &.includeIcon {
      float: none;
    }
  }
  
  
  // ======================================================================================
  // Breadcrumb
  // ======================================================================================
  
  .vs-breadcrumb--ol {
    a {
  
      &:focus,
      &:hover {
        color: $primary;
      }
    }
  
    .active {
      color: $primary;
    }
  }
  
  
  // ======================================================================================
  // Check-box
  // ======================================================================================
  
  .vs-checkbox-small {
    .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
      margin-top: 6px;
      margin-left: -1px;
      transform: translateY(-3px);
    }
  }
  
  .vs-checkbox--check {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  // ======================================================================================
  // Chip
  // ======================================================================================
  
  .con-chips {
    .con-chips--input {
      border: none;
    }
  
    .con-vs-chip {
      margin: .75rem;
    }
  
    .con-chips--remove-all {
      right: 9px;
      > .vs-icon {
        font-size: 1.3rem;
      }
    }
  }
  
  .con-vs-chip {
    min-height: 26px;
    min-width: 26px;
    font-size: .8rem;
  }
  
  
  // ======================================================================================
  // Collapse
  // ======================================================================================
  
  .vs-collapse-item--header {
    font-size: 1.2rem;
    padding: 1rem;
  }
  
  .con-content--item {
    font-size: 1rem;
    padding: 1rem;
  }
  
  .vs-collapse.default,
  .vs-collapse.shadow,
  .vs-collapse.border,
  .vs-collapse.margin {
    .open-item {
      .con-content--item {
        opacity: 1;
        padding: 1rem;
      }
    }
  }
  
  
  // ======================================================================================
  // Dialog
  // ======================================================================================
  
  .con-vs-dialog {
    z-index: 52005;
  
    .vs-dialog {
      header {
        .dialog-title {
          padding: 0.8rem;
          color: inherit;
        }
  
        span.after {
          width: 0
        }
      }
  
      .vs-dialog-text {
        padding: 1rem;
        font-size: 1rem;
      }
  
      footer {
        padding: 1rem;
  
        .vs-button:last-of-type {
          margin-left: .5rem !important;
          border-color: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
  }
  
  
  // ======================================================================================
  // DropDown
  // ======================================================================================
  
  .vs-con-dropdown {
    color: inherit;
    font-size: 1rem;
  }
  
  // Dropdown Button padding
  .dropdown-button-container {
    .vs-button {
      padding: .72rem 1.5rem !important;
    }
  
    .vs-button-line {
      padding: 9px 10px !important;
    }
  }
  
  .con-vs-dropdown--menu {
    z-index: 42000;
  }
  
  
  // ======================================================================================
  // Input
  // ======================================================================================
  
  .vs-input--placeholder {
    top: 0px;
  }
  
  .vs-input--input.hasIcon:not(.icon-after-input) {
    &+.vs-input--placeholder {
      padding-left: 3rem !important;
    }
    &:focus+.vs-placeholder-label {
      padding-left: .7rem !important;
    }
  }
  
  // Search Box Text
  // .vs-input--input.large.hasIcon {
  //   padding: 1rem .8rem 1rem 3rem;
  // }
  
  // INPUT FORM ELEMENT COMPONENT
  .vs-con-input {
    .vs-inputx {
      padding: .7rem;
      font-size: 1rem;
      border: 1px solid rgba(0, 0, 0, .2);
    }
  
    .vx-inputx:not(.input-rounded-full) {
      border-radius: 5px;
    }
  }
  
  .vs-input--input {
    &.normal {
      padding: .7rem !important;
      font-size: 1rem !important;
  
      ~ .vs-input--icon {
        &.feather {
          top: 9px;
        }
      }
    }
  
    &.large {
      padding: 1rem !important;
      font-size: 1.2rem !important;
  
      &.hasIcon {
        padding: 1rem 0.8rem 1rem 3rem !important;
      }
  
      ~.vs-input--icon {
        top: 1rem !important;
        left: 0.8rem !important;
      }
    }
  
    &.small {
      padding: .4rem !important;
      font-size: .8rem !important;
  
      ~ .vs-input--icon {
        &.feather {
          top:7px !important;
        }
      }
    }
  
    &.hasIcon {
      padding: 0.7rem 1rem 0.7rem 3rem !important;
  
      &.icon-after-input {
        padding: 0.7rem 3rem 0.7rem 0.7rem !important;
      }
    }
  }
  
  .vs-input--placeholder {
    &.normal {
      padding: .7rem !important;
    }
  
    &.large {
      padding: 1rem !important;
    }
  
    &.small {
      padding: .2rem .6rem !important;
      font-size: .8rem !important;
    }
  }
  
  .vs-input--icon {
    &.feather {
      // top: 10px;
      padding: .2rem .5rem 0rem .4rem;
    }
  }
  
  // Rounded Input
  .vs-input.input-rounded-full {
  
    // Fixes input element don't get rounded borders
    input {
      border-radius: 20px;
    }
  
    .vs-input--input.hasIcon {
      padding: .8rem 1rem 0.8rem 3rem !important;
    }
  
    .input-span-placeholder {
      padding-left: 3rem !important;
      padding-top: .7rem !important;
    }
  
    .vs-icon {
      margin-top: .1rem !important;
      margin-left: 0.6rem !important;
      font-size: 1rem !important;
    }
  }
  
  
  // ======================================================================================
  // List
  // ======================================================================================
  
  .vs-list--item {
    .list-titles {
      .vs-list--subtitle {
        font-size: .85rem;
      }
    }
  
    &:last-child {
      border-bottom: none;
    }
  }
  
  
  // ======================================================================================
  // NavBar
  // ======================================================================================
  
  .vs-navbar--item {
    a {
      .vs-navbar & {
        color: inherit;
      }
  
      .vs-navbar-color-transparent &:hover {
        color: $primary !important;
      }
    }
  
    .vs-navbar-gradient & {
      &.is-active-item a {
        color: #fff !important;
      }
    }
  }
  
  
  // ======================================================================================
  // Notification
  // ======================================================================================
  
  .vs-notifications {
    z-index: 200000 !important;
  
    h3 {
      color: $white;
      font-weight: 600;
      font-size: $h5-font-size;
    }
  }
  
  
  // ======================================================================================
  // Pagination
  // ======================================================================================
  .vs-pagination--li.is-current {
    border-radius: 50%;
  
    .effect {
      border-radius: 50%;
    }
  }
  
  .vs-pagination--li {
    &:hover {
      &:not(.is-current) {
        color: var(--vs-color-pagination) !important;
      }
    }
  }
  
  .vs-pagination--ul {
    padding: 0;
  }
  
  
  // ======================================================================================
  // PopUp
  // ======================================================================================
  
  .vs-popup--title,
  .vs-notifications {
    h3 {
      margin-bottom: 0;
    }
  }
  
  .con-vs-popup {
    z-index: 53000;
  
    .vs-popup--content {
      padding: 1rem;
      font-size: 1rem;
    }
  }
  
  
  // ======================================================================================
  // Radio
  // ======================================================================================
  
  // Fixes check-box gets in center
  .con-vs-checkbox,
  .con-vs-radio {
    justify-content: flex-start !important;
  }
  
  .vs-radio--label {
    line-height: 1;
  }
  
  .con-vs-radio {
    display: inline-flex;
  }
  
  // ======================================================================================
  // Select
  // ======================================================================================
  
  .con-select {
    .vs-select--input {
      padding: 10px;
      font-size: 1rem;
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }
  
  .vs-select--options {
    font-size: 1rem;
    border: 1px solid #eee;
    z-index: 530001;
  
    span {
      font-size: 1rem;
    }
  }
  
  .v-select {
    .dropdown-toggle {
      .vs__actions {
        .clear {
          padding-top: 4px;
        }
      }
    }
  }
  
  
  // ======================================================================================
  // Sidebar
  // ======================================================================================
  
  .vs-sidebar {
    height: calc(var(--vh, 1vh) * 100);
    .vs-sidebar--items {
      overflow: hidden;
      height: 100%;
    }
  }
  
  
  // ======================================================================================
  // Switch
  // ======================================================================================
  
  .vs-switch--text {
    font-size: 0.7rem;
  }
  
  
  // ======================================================================================
  // Table
  // ======================================================================================
  .vs-con-table {
    background: transparent;
  
    .vs-table--header {
      .vs-table--search {
        padding: 1rem 0;
  
        // align text in search input
        .vs-table--search-input {
          padding: 10px 28px;
          border: 1px solid rgba(0, 0, 0, .1);
          font-size: 0.9rem;
        }
  
        .vs-table--search-input:focus+i {
          left: 10px;
        }
  
        // align search icon in table
        i {
          left: 10px;
        }
      }
  
    }
  
    .vs-con-tbody {
      background: #f8f8f8;
      border: 2px solid #f8f8f8;
      width: 100%;
      overflow: auto;
  
      // Justify checkboxe center
      .con-vs-checkbox {
        justify-content: center !important;
      }
  
      .vs-table--tbody-table {
        font-size: 1rem;
  
        .tr-spacer {
          height: 2px;
        }
  
        .tr-table {
          .tr-expand {
            td {
              padding: 0;
            }
          }
  
          td {
            padding: 1rem;
          }
        }
  
        .vs-table--thead {
          th {
            padding: 10px 15px;
          }
  
          .con-td-check {
            background: transparent;
            box-shadow: none;
          }
        }
  
        .tr-values {
          .vs-table--td {
            padding: 10px 15px;
          }
        }
      }
    }
  
    .vs-table--pagination {
      margin-top: 1rem;
    }
  }
  
  
  // ======================================================================================
  // Tabs
  // ======================================================================================
  
  .con-slot-tabs {
    width: 100%;
  }
  
  .vs-tabs--li {
    white-space: nowrap;
  
    button {
      font-size: 1rem;
      font-weight: 500;
    }
  
    .vs-tabs-position-left & {
      padding: .35rem 0.3rem;
    }
  }
  
  // Remove after merge of: https://github.com/lusaxweb/vuesax/pull/637
  .vs-tabs--li {
    button {
      font-family: inherit;
      color: inherit;
    }
  
    .vs-icon-primary,
    .vs-icon-success,
    .vs-icon-warning,
    .vs-icon-danger,
    .vs-icon-dark {
      color: inherit;
    }
  }
  
  // Remove after merge of: https://github.com/lusaxweb/vuesax/pull/637
  .vs-tabs {
    .con-tab {
      .vs-button-filled {
        &:hover {
          color: #fff !important;
        }
      }
    }
  }
  
  
  // ======================================================================================
  // Text-area
  // ======================================================================================
  
  .vs-textarea {
    font-size: 1rem;
    color: inherit;
    font-family: $font-family-sans-serif;
    line-height: 1.6;
  }
  
  .vs-con-textarea {
    border: 1px solid rgba(0, 0, 0, .2) !important;
  
    > h4 {
      color: inherit;
    }
  }
  
  
  // ======================================================================================
  // Tool-tip
  // ======================================================================================
  
  .vs-tooltip {
    z-index: 52000;
  }
  
  
  // ======================================================================================
  // Upload
  // ======================================================================================
  
  .con-img-upload {
    overflow: hidden;
    padding: 0.6rem;
  
    .img-upload {
      margin: 15px;
    }
  }
  
  .view-upload {
    z-index: 52000;
  }
  