//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
}

.width1 {
  width: 1%;
}

.width10 {
  width: 10%;
}


.width20 {
  width: 20%;
}

.width40 {
  width: 40%;
}

.width60 {
  width: 60%;
}