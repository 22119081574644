//
// calendar.scss
//
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/bootstrap/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
// @import '~@fullcalendar/list/main.css';

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border {
  .modal-footer {
    border-top: none;
  }
}

.fc-toolbar {
  margin: 10px 0 5px 0;

  h2 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-transform: uppercase;
  }
}

.fc-day-grid-event {
  .fc-time {
    font-weight: $font-weight-medium;
  }
}

th.fc-day-header {
  padding: 0.5rem 0;
}

.fc-day {
  background: transparent;
}

.fc-toolbar {
  .fc-state-active,
  .ui-state-active,
  button:focus,
  button:hover,
  .ui-state-hover {
    z-index: 0;
  }
}

.fc {
  th.fc-widget-header {
    padding: 10px 0;
    font-size: 13px;
    font-weight: $font-weight-medium;
    line-height: 20px;
    text-transform: uppercase;
    background: $gray-300;
  }
}

.fc-unthemed {
  th,
  td,
  thead,
  tbody,
  .fc-divider,
  .fc-row,
  .fc-popover {
    border-color: $gray-300;
  }

  td.fc-today,
  .fc-divider {
    background: $gray-300;
  }
}

.fc-button {
  height: auto;
  padding: 6px 12px;
  margin: 0 3px;
  color: $gray-700;
  text-transform: capitalize;
  background: $gray-300;
  border: none;
  border-radius: 3px;
  box-shadow: none;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: $gray-300;
}

.fc-state-highlight {
  background: $gray-300;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: $white;
  text-shadow: none;
  background-color: $primary;
}

.fc-cell-overlay {
  background: $gray-300;
}

.fc-unthemed {
  .fc-today {
    background: $white;
  }
}

.fc-event {
  padding: 5px 5px !important;
  margin: 5px 7px !important;
  font-size: 0.8125rem !important;
  text-align: center !important;
  cursor: move !important;
  border: none !important;
  border-radius: 2px !important;
}

.external-event {
  padding: 8px 10px;
  margin: 10px 0;
  color: $white;
  cursor: move;
  border-radius: 4px;
}

.fc-basic-view {
  td.fc-week-number {
    span {
      padding-right: 8px;
    }
  }

  td.fc-day-number {
    padding-right: 8px;
  }

  .fc-content {
    color: $white;
  }
}

.fc-time-grid-event {
  .fc-content {
    color: $white;
  }
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block;
    .fc-left,
    .fc-right,
    .fc-center {
      display: block;
      float: none;
      margin: 10px 0;
      clear: both;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

// overriding button sizes
.fc-toolbar {
  .btn {
    @include button-size(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $btn-font-size-sm,
      $btn-line-height-sm,
      $btn-border-radius-sm
    );
  }
}
